<template>
  <aside>
    <header>
      <h2>Enable Postpay Rewards</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <p>HiThrive's postpay rewards feature is a great alternative to pre-funding your reward account with points to cover redemptions. Instead, your saved credit card will be charged as redemptions occur.</p>
      <ul class="how-it-works">
        <li>
          <h4>Enable postpay rewards</h4>
          <p>Use the switch below to enable postpay rewards and add a credit card for payment.</p>
        </li>
        <li>
          <h4>Make redemptions</h4>
          <p>Your team will immediately be able to make paid redemptions using their point balance.</p>
        </li>
        <li>
          <h4>Pay for redemptions</h4>
          <p>As we process redemptions throughout the day, we'll charge your credit card for your team's outstanding redemptions.</p>
        </li>
      </ul>

      <div class="switch-wrap">
        <ht-switch v-model="use_postpay" :loading="check_postpay" :disabled="team.reward_payment_type === 1" />
        <span>Enable postpay rewards?</span>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :disabled="check_postpay === true || use_postpay === false || team.reward_payment_type === 1" :class="{'loading': loading}" @click="savePostpay">Save</button>
    </footer>
  </aside>
</template>

<script>
export default {
  computed: {
    team() {
      return this.$store.state.team;
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      check_postpay: false,
      use_postpay: null
    }
  },
  watch: {
    use_postpay(newVal, oldVal) {
      if(oldVal === false && newVal === true) {
        //see if we can enable post pay
        this.togglePostpay()
      }
    }
  },
  created() {
    this.use_postpay = this.team.reward_payment_type === 1;
  },
  methods: {
    async togglePostpay() {
      //check payment method
      this.check_postpay = true;

      const _checkPaymentMethod = async () => {
        const resp = await this.$api.Billing.get_payment_method();
        if( resp.sources.length == 0 ) {
          return false;
        }
        else {
          return resp.sources[0];
        }
      };

      const paymentMethod = await _checkPaymentMethod()
      if(paymentMethod === false) {
        //Prompt to add credit card
        // TODO: open credit card modal
        this.$root.$emit(
        'openModal',
        'Billing/SetPaymentMethod',
        {},
        (resp) => {
          this.check_postpay = false;
          if(resp === 'PAYMENT_METHOD_SAVED') {

          }
          else {
            this.use_postpay = false;
          }
        }
      )
      }
      else {
        this.check_postpay = false;
      }
    },
    async savePostpay() {
      this.$store.commit('loading', true)
      await this.$api.Billing.set_reward_payment_method(this.use_postpay === true ? 'POSTPAY' : 'PREPAY')
      this.$store.commit('loading', false)

      this.$store.dispatch('populateUser')

      this.$emit('done')
    },
    close() {
      this.$emit('done')
    }
  }
}
</script>

<style lang="scss" scoped>
.how-it-works {
  display: flex;
  margin: 0 -7px 35px;
  padding: 0;
  align-items: flex-start;

  > li {
    display: block;
    background: $light;
    border-radius: 10px;
    padding: 25px;
    margin: 0 7px;
    flex: 1 1 auto;
    height: auto;
    position: relative;

    > h4 {
      font-size: 15px;
      font-weight: 700;
    }

    > p {
      margin: 0;
      font-size: 14px;
    }

    &:after {
      content: '\f061';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
      display: block;
      position: absolute;
      right: -23px;
      top: 50%;
      margin-top: -15px;
      background: #fff;
      box-shadow: rgba(0,0,0,0.05) 0 3px 8px;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      z-index: 99;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}
</style>