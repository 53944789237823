import { render, staticRenderFns } from "./EnablePostpay.vue?vue&type=template&id=1352f43e&scoped=true"
import script from "./EnablePostpay.vue?vue&type=script&lang=js"
export * from "./EnablePostpay.vue?vue&type=script&lang=js"
import style0 from "./EnablePostpay.vue?vue&type=style&index=0&id=1352f43e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1352f43e",
  null
  
)

export default component.exports